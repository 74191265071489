import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { get } from "lodash";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Input } from "antd";
import cx from "classnames";
import { isMobile } from "react-device-detect";

import Pages from "../../components/Pages";
import { ContentBlock } from "../../components/Blocks";
import { Breadcrumbs } from "../../components/Layout";
import Meta from "../../components/Meta";
import { Share, useSearchAudioWidget } from "../../widgets";
import { removePreloader } from "../../helpers";
import { ALL_AUDIOGUIDES_MAX_UPDATE, AUDIOGUIDES_LIST } from "../../queries/queries.graphql";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { Content } from "./components";

import "./audioguides.scss";

export const query = graphql`
	query audioguidesList($slug: String!) {
		hasura {
			...AudioguidesList
			...AudioCategories
		}
	}
`;

export default function AudioguidesCategory({ data, pageContext }) {
	const { theme } = useThemeContext();
	const { loading, value, results, onChnage } = useSearchAudioWidget();

	const limit = 5;
	const pageSlug = get(pageContext, "slug", "");
	const maxUpdated = new Date(get(data, "hasura.v_media_audio_guides_aggregate.aggregate.max.updated_at", new Date()));

	const [audioguides, setAudioguides] = useState(get(data, "hasura.v_media_audio_guides", []));
	const [offset, setOffset] = useState(limit);

	const audioguidesOptions = {
		displayName: "AudioguidesQuery",
		fetchPolicy: "cache-and-network",
		ssr: false,
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(
		ALL_AUDIOGUIDES_MAX_UPDATE,
		{
			variables: {
				slug: pageSlug,
			},
			...audioguidesOptions,
		},
	);

	const [loadMore, { called: loadMoreCalled, loading: loadMoreLoading, error: loadMoreError, data: loadMoreData }] = useLazyQuery(
		AUDIOGUIDES_LIST,
		{
			variables: {
				limit,
				offset,
				slug: pageSlug,
			},
			...audioguidesOptions,
		},
	);
	//
	const [loadAudioGuides, { called: audioguidesCalled, loading: audioguidesLoading, data: audioguidesData, error: audioguidesError }] = useLazyQuery(
		AUDIOGUIDES_LIST,
		{
			variables: {
				limit,
				offset: 0,
				slug: pageSlug,
			},
			...audioguidesOptions,
		},
	);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "v_media_audio_guides_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadAudioGuides();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load AudioguidesMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (audioguidesData && !audioguidesError) {
			setAudioguides(get(audioguidesData, "v_media_audio_guides", audioguides));
			removePreloader(audioguidesCalled);
		} else if (audioguidesError) {
			console.error("Invalid load AudioguidesData", { audioguidesError });
			removePreloader();
		}
	}, [audioguidesLoading]);

	useEffect(() => {
		if (loadMoreCalled && !loadMoreError && !loadMoreLoading) {
			setAudioguides([...audioguides, ...get(loadMoreData, "v_media_audio_guides", [])]);
			setOffset(get(loadMoreData, "v_media_audio_guides", []).length < limit ? null : offset + limit);
		} else if (loadMoreError) {
			setOffset(null);
		}

		removePreloader(loadMoreCalled);
	}, [loadMoreLoading]);

	function onLoadMoreClick() {
		loadMore({
			variables: {
				offset,
			},
		});
	}

	const page_title_full = get(audioguides, "page_title_full", "");
	const page_title_short = get(audioguides, "page_title_short", "");
	const main_image_preview = get(audioguides, "main_image_preview", {});
	const main_image = get(audioguides, "main_image", {});
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const url = "/media/audioguides";

	const metaData = {};

	const resultFilteredByCategory = results.filter(item => item.categorySlug === pageSlug);

	const items = resultFilteredByCategory.length ? resultFilteredByCategory : audioguides;

	return (
		<Pages
			seo={{}}
			url={url}
		>
			<div itemScope itemType="https://schema.org/AudioObject" className={"audioguides"}>
				<Meta data={metaData} />
				<ContentBlock key={"breadcrumbs"}>
					<div className="container audioguides__breadcrumbs-search">
						<Breadcrumbs
							currentLabel={get(pageContext, "title", "")}
							pageContext={
								{
									item: {
										title_full: "Медиатека",
										slug: "media",
									},
								}
							}
							classNames={"audioguides__breadcrumbs"}
						/>
						<div style={{
							position: "relative",
						}}>
							<Input
								placeholder={"Введите запрос"}
								className={cx(`audioguides-search__input audioguides-search__input_${theme.color.key}`, { search__input_mobile: isMobile })}
								onChange={event => onChnage(event.target.value)}
							/>
						</div>
					</div>
				</ContentBlock>

				<Content
					loading={loading}
					value={value}
					results={results}
					items={items}
					offset={offset}
					loadMoreLoading={loadMoreLoading}
					onLoadMoreClick={onLoadMoreClick}
				/>

				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

AudioguidesCategory.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

AudioguidesCategory.defaultProps = {
	data: {},
	pageContext: {},
};
