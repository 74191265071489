import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import Preloader from "../../../components/Preloader";
import { ContentBlock } from "../../../components/Blocks";
import { showMore } from "../../../constants";
import EmptyData from "../../../components/EmptyData";
import { useThemeContext } from "../../../components/Layout/ThemeContext";
import AudiogiodeList from "../../../components/Lists/AudioguideList";
import { removeEmptyKeys } from "../../../helpers";

export function Content({
	loading,
	value,
	results,
	items,
	offset,
	loadMoreLoading,
	onLoadMoreClick,
}) {
	const { theme } = useThemeContext();

	if (loading) {
		return <Preloader />;
	}

	if (value && !results.length) {
		return <EmptyData />;
	}

	return items.length ? (
		<ContentBlock key={"contents"}>
			<div className={"container audioguides__list"}>
				<AudiogiodeList items={items} />
			</div>
			{
				!results.length && (
					<div className="d-flex justify-content-center pb-5">
						<Button
							type={"primary"}
							disabled={loadMoreLoading || !offset}
							loading={loadMoreLoading}
							onClick={onLoadMoreClick}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
						>
							{showMore}
						</Button>
					</div>
				)
			}
		</ContentBlock>
	) : <EmptyData />;
}

Content.propTypes = {
	loading: PropTypes.bool,
	value: PropTypes.string,
	results: PropTypes.array,
	items: PropTypes.array,
	offset: PropTypes.number,
	loadMoreLoading: PropTypes.array,
	onLoadMoreClick: PropTypes.func,
};

Content.defaultProps = {
	loading: false,
	value: "",
	results: [],
	items: [],
	offset: 0,
	loadMoreLoading: false,
	onLoadMoreClick: () => Function,
};
