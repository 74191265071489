import React from "react";
import get from "lodash/get";
import { List, Button } from "antd";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { AudioPlayer } from "../../Media";
import { makeUrl, removeEmptyKeys } from "../../../helpers";
import { useThemeContext } from "../../Layout/ThemeContext";

export default function AudiogiodeList({ items }) {
	const { theme } = useThemeContext();

	return (
		<List
			itemLayout={"vertical"}
			size={"large"}
			dataSource={items}
			className={`audioguides__list_${theme.color.key}`}
			renderItem={item => <ListItem item={item} />}
		/>
	);
}

AudiogiodeList.propTypes = {
	items: PropTypes.array,
};

AudiogiodeList.defaultProps = {
	items: [],
};

function ListItem({ item }) {
	const { theme } = useThemeContext();

	const belltowerSrc = makeUrl.belltower(get(item, "belltowers_media_audios[0].belltower", ""));
	const cathedralSrc = makeUrl.cathedral(get(item, "cathedrals_media_audios[0].cathedral", ""));
	const chapelrSrc = makeUrl.chapel(get(item, "chapels_media_audios[0].chapel", ""));
	const churchSrc = makeUrl.church(get(item, "churches_media_audios[0].church", ""));
	const citySrc = makeUrl.city(get(item, "cities_media_audios[0].city", ""));
	const cityObjectrSrc = makeUrl.cityObject(get(item, "city_objects_media_audios[0].city_object", ""));
	const hermitageSrc = makeUrl.hermitage(get(item, "hermitages_media_audios[0].hermitage", ""));
	const shrineSrc = makeUrl.shrine(get(item, "media_audios_shrines[0].shrine", ""));
	const templeSrc = makeUrl.temple(get(item, "media_audios_temples[0].temple", ""));
	const wellSrc = makeUrl.well(get(item, "media_audios_wells[0].well", ""));
	const monasterySrc = makeUrl.monastery(get(item, "monasteries_media_audios[0].monastery", ""));
	const museumSrc = makeUrl.museum(get(item, "media_audios_museums[0].museum", ""));
	const objectSrc = belltowerSrc || chapelrSrc || churchSrc || citySrc || cityObjectrSrc || hermitageSrc || shrineSrc || templeSrc || wellSrc || monasterySrc || museumSrc || cathedralSrc;

	const src = get(item, "main_image_preview.src", "");
	const teaser = get(item, "teaser", "");
	const title = get(item, "title_full", get(item, "page_title_short", ""));
	const slug = get(item, "slug", "/");
	const url = get(item, "audio.src", "");

	const themeStyles = removeEmptyKeys({
		fontFamily: theme.fontFamily,
		fontSize: theme.fontSize.text,
		lineHeight: theme.lineHeight.text,
		color: theme.color.text,
		backgroundColor: theme.color.body,
	});

	return (
		<List.Item
			key={title}
			extra={
				<div className={"audioguides__img"}>
					{
						src && (
							<img
								width={"100%"}
								height={"100%"}
								alt={title}
								src={src}
							/>
						)
					}
				</div>
			}
		>
			<List.Item.Meta
				title={title}
				description={<AudioPlayer url={url} slug={slug} />}
			/>
			<div
				className={"mb-3"}
				style={themeStyles}
			>
				{teaser}
			</div>
			<div className={"audioguides__btns"}>
				<Link to={slug}>
					<Button
						className={"col-12"}
						style={themeStyles}
					>
                        Открыть описание
					</Button>
				</Link>
				{
					objectSrc && (
						<Link to={objectSrc}>
							<Button
								className={"col-12"}
								style={themeStyles}
							>
                                Перейти на страницу объекта
							</Button>
						</Link>
					)
				}
			</div>
		</List.Item>
	);
}

ListItem.propTypes = {
	item: PropTypes.object,
};

ListItem.defaultProps = {
	item: {},
};

